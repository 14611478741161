import { APIDataHandler } from "@core/models/data.handler";
import { ILoginUserData, ILoginResponse } from "@features/auth/require-update-auth-info/interfaces/login-response.interface";

export class AuthModel implements APIDataHandler<AuthModel> {
  listmenu_active: string[] = [];
  result!: ILoginUserData;
  token!: string;

  mapDataFromAPI(args: ILoginResponse) {
    this.listmenu_active = args?.listmenu_active?.map((id) => id.toString());
    this.result = args?.result;
    this.token = args?.token;
    return this;
  }
}
