import { Injectable, inject } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthModel } from '../../login/models/auth.model';
import _ from 'lodash';
import { AuthErrorModel } from '../../login/models/auth-error.model';
import { HttpClient } from '@angular/common/http';
import { DecentralierService } from '@shared/directives/zaa-decentralization/decentralization.services';
import { API_ENDPOINTS } from '@config/api.config';
import { AppStorageService, StorageLocation } from '@core/app-store/app-storage.service';
import { JWTHelpler } from '@shared/helpers/jwt/jwt.helper';
import { ILoginResponse } from '@features/auth/require-update-auth-info/interfaces/login-response.interface';
import { RequireUpdateAuthInfoService } from '@features/auth/require-update-auth-info/services/require-update-info.service';
import { environment } from '@config/environments/environment';
import { APP_ID_FOR_PROJECT } from '@features/auth/config/const';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly httpClient = inject(HttpClient);
  private readonly detranalizerService = inject(DecentralierService);
  private readonly appStorage = inject(AppStorageService);
  verifyAuthInfo = inject(RequireUpdateAuthInfoService);

  authEvent = new Subject<any>();
  authEvent$ = this.authEvent.asObservable();

  private readonly STORAGE_LOCATION = { location: StorageLocation.LOCAL_STORAGE };
  API_ENDPOINT = API_ENDPOINTS.authen + '/user/enter-system';

  private readonly API_ENDPIONTS = {
    // Đăng nhập vào hệ thống.
    LOGIN: `${this.API_ENDPOINT}/login-normal`,

    // WARNING: FEATURE NOT IMPEMENTED
    LOGIN_BY_TOKEN: `${this.API_ENDPOINT}/login-token`,

    // Đăng xuất khỏi hệ thống,
    LOGOUT: `${this.API_ENDPOINT}/logout`
  }

  loginCountErrorStorageKey = this.appStorage.localStorageKeys.feature.auth.loginErrorCount;
  appENV = environment.application;
  private authModel!: AuthModel;

  constructor() {
    this.authModel = new AuthModel()
  }

  login(username: string, password: string): Observable<AuthModel> {
    if (username?.trim().length == 0 || password.trim().length == 0) {
      throw new Error(`Username and password should not be empty`);
    }
    const url = this.API_ENDPIONTS.LOGIN;
    const appId = APP_ID_FOR_PROJECT;

    const requestBody = {
      username: username,
      password: password,
      app: appId,
    };

    const response = this.httpClient.post<ILoginResponse>(url, requestBody);

    return response.pipe(
      catchError((err) => {
        this.countLoginError();
        return throwError(() => AuthErrorModel.handle(err))
      }),
      map((response: any) => {
        return this.authModel.mapDataFromAPI(response);
      }),

      // lưu token và thông tin user phục vụ việc lấy dữ liệu từ server và hiển thị thông tin user
      tap((formated) => {
        this.setAuthResponseToStorage(formated.token, formated.result, formated.listmenu_active);
        this.clearLoginCountError();
        this.verifyAuthInfo.clearAll();
      }),
    );
  }

  // ex http://localhost:4200/?redirect=dashboard&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE5MzciLCJOaGFuVmllbklEIjoiTk5WMDA1MDU4OCIsImFwcCI6Ik1PQklMRV9IVVRFQ0giLCJpcCI6Ijo6ZmZmZjoxMjcuMC4wLjEiLCJzZXJ2aWNlX2lkIjoiMTkzNyIsImlhdCI6MTcwMjUzMzk4ODU3MCwidHlwZSI6IkxPR0lOX1RPS0VOIiwiZXhwIjoxNzAyNTMzOTg4NTk4fQ.n8U3CupiWvsQHg98N00Smgtb0QqR6YlIvM5Gdw8AscA
  handleAuthenticateByToken(token: string) {
    if (token?.length == 0) {
      throw new Error(`Token should not be empty!`);
    }
    if (!new JWTHelpler().idValid(token)) {
      throw new Error(`Invalid access token - need to login again!`);
    }

    return this.logout(token).pipe(
      switchMap(() => {
        return this.loginByTokenRequest().pipe(
          tap((response) => {
            // NOTE:Logic set accesss token when navigate to localstorage - dont use response access token!!!
            this.setAuthResponseToStorage(token, response.result, response.listmenu_active);
          })
        )
      })
    );
  }

  setAuthResponseToStorage(token: string, authInfo: object, listmenu: string[] | number[]) {
    const { accessToken, actionIds, userInformation } =
      this.appStorage.localStorageKeys.feature.auth;

    this.appStorage.setItem(accessToken, token, this.STORAGE_LOCATION);
    this.appStorage.setItem(userInformation, authInfo, this.STORAGE_LOCATION);
    this.appStorage.setItem(actionIds, listmenu, this.STORAGE_LOCATION);
  }

  loginByTokenRequest = (): Observable<AuthModel> => {
    const endpoint = this.API_ENDPIONTS.LOGIN_BY_TOKEN;
    return this.httpClient.post<ILoginResponse>(endpoint, {}).pipe(
      map((response) => this.authModel.mapDataFromAPI(response)),
    );
  }

  // Nếu có token thì không gọi api logout
  logout(token?: string) {
    const clearLocalStorageItem = () => {
      const { tabSetting, themeSetting } = this.appStorage.localStorageKeys.feature.layouts;
      const language = this.appStorage.localStorageKeys.global.transloco.currentLanguage;

      // Get ra rồi gán vô lại đở tốn xử lý hơn tìm cho đúng rồi remove né cái đó ra
      const currentThemeMode = this.appStorage.getItem<any>(tabSetting.themeMode, this.STORAGE_LOCATION);
      const currentTabSetting = this.appStorage.getItem<any>(themeSetting, this.STORAGE_LOCATION);
      const currentLangulage = this.appStorage.getItem<any>(language, this.STORAGE_LOCATION);

      this.appStorage.removeAllIn(this.STORAGE_LOCATION);
      this.appStorage.removeAllIn({ location: StorageLocation.SESSION_STORAGE });

      this.detranalizerService.setElements([]); // <--- Clear active element in state;

      this.appStorage.setItem(tabSetting.themeMode, currentThemeMode, this.STORAGE_LOCATION);
      this.appStorage.setItem(themeSetting, currentTabSetting, this.STORAGE_LOCATION);
      this.appStorage.setItem(language, currentLangulage, this.STORAGE_LOCATION);
    }
    if (token && token.length !== 0) {
      clearLocalStorageItem();

      const { accessToken } = this.appStorage.localStorageKeys.feature.auth;
      // Set token to storage to use auth header in global
      this.appStorage.setItem(accessToken, token, this.STORAGE_LOCATION);
      return of({ keepToken: true });
    } else {
      return this.httpClient.post(this.API_ENDPIONTS.LOGOUT, {}).pipe(
        tap(value => {
          // Delay to optimize ux
          setTimeout(() => {
            clearLocalStorageItem();
            this.authEvent.next({ event: 'logout'})
          }, 300);
        }),
      )
    }


  }

  countLoginError() {
    const key = this.loginCountErrorStorageKey;
    let countLoginError = +this.appStorage.getItem(key, this.STORAGE_LOCATION);
    if (countLoginError) {
      countLoginError += 1;
    } else {
      countLoginError = 1;
    }
    this.appStorage.setItem(key, countLoginError, this.STORAGE_LOCATION);
    return countLoginError;
  }

  getLoginCountError() {
    const key = this.loginCountErrorStorageKey;
    const countLoginError = +this.appStorage.getItem(key, this.STORAGE_LOCATION);
    return countLoginError;
  }

  clearLoginCountError() {
    this.appStorage.removeItems([this.loginCountErrorStorageKey], this.STORAGE_LOCATION);
  }

  isLoggedIn = (): boolean => {
    // return new JWTHelpler().idValid(this.getAccessToken());
    const token = this.getAccessToken();
    return token !== undefined && token?.length !== 0;
  }

  getAccessToken = () => {
    const token = this.appStorage.getItem<string>(
      this.appStorage.localStorageKeys.feature.auth.accessToken,
      this.STORAGE_LOCATION
    );
    return token;
  }

  buildTokenByJWTPrefix = () => {
    return `Bearer ${this.getAccessToken()}`;
  }

  // Example: Mobile hutech
  setCurrentAppKey(appKey: string) {
    this.appStorage.setItem(
      this.appStorage.localStorageKeys.global.appKey,
      appKey,
      this.STORAGE_LOCATION
    );
  }
}
